<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-list-item class="lessMargin" v-on="on">
        <v-list-item-icon>
          <v-icon small style="margin-top: -4px !important"> $edit </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("desarchivate") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>{{ $t("archives.text_desarchive") }}</v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0">{{
            $t("archives.text_desarchivate")
          }}</v-col>
          <v-col cols="12" class="py-0" v-for="c in camps" :key="c">
            <v-checkbox
              :label="
                $tc(
                  c == 'tattooer' ? 'artists' : c == 'expense' ? 'economy' : c,
                  2
                )
              "
              on-icon="mdi-check-circle"
              off-icon="mdi-circle-outline"
              v-model="form[c]"
              hide-details
              @click="error = false"
              v-bind:class="[{ 'error--text': error }]"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="error--text py-0" v-if="error">{{
            $t("archives.text_error_2")
          }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>

        <v-btn
          @click="dialog = false"
          style="height: 30px; width: 100px"
          elevation="0"
          outlined
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          style="
            width: 100px !important;
            color: #363533;
            height: 30px;
            padding-top: 10px !important;
          "
          elevation="0"
          @click="save"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: "" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "UpdateArchive",
  props: ["archive"],
  data() {
    return {
      form: {},
      dialog: false,
      error: false,
      camps_all: [
        "tattooer",
        "appointment",
        "expense",
        "customer",
        "inventory",
      ],
    };
  },
  mounted() {
    this.form.date = moment().format("YYYY-MM-DD");
    this.form.studio_id = this.$store.state.auth.user.studio.id;
  },
  computed: {
    camps() {
      let a = [];
      this.camps_all.forEach((c) => {
        if (this.archive[c]) a.push(c);
      });
      return a;
    },
  },
  methods: {
    ...mapActions("archive", ["updateArchives"]),
    save() {
      this.error = false;
      let error = true;
      Object.keys(this.form).forEach((key) => {
        if (key !== "date" && key !== "studio_id")
          if (this.form[key]) error = false;
      });
      console.log("save", error);
      if (error) {
        this.error = true;
        return;
      }
      this.$confirm("¿Estas seguro que quieres desarchivar?").then(() => {
        this.updateArchives({ formData: this.form, id: this.archive.id }).then(
          () => {
            this.$alert(this.$t("desarchived_ok"));
            this.dialog = false;
            this.$emit("update");
            this.form = {
              date: moment().format("YYYY-MM-DD"),
              studio_id: this.$store.state.auth.user.studio.id,
            };
          }
        );
      });
    },
  },
};
</script>